import { UserRole } from '../../graphql/@types/schema'

type RoleOption = {
  id: UserRole
  label: string
}

const roleOptions: RoleOption[] = [
  {
    id: UserRole.Admin,
    label: 'Admin',
  },
  {
    id: UserRole.Staff,
    label: 'Staff',
  },
  {
    id: UserRole.Viewer,
    label: 'Viewer',
  },
  {
    id: UserRole.Vendor,
    label: 'Vendor',
  },
  {
    id: UserRole.EndUser,
    label: 'End user',
  },
]

const roleOptionsToLabelMap: Map<UserRole, string> = new Map()
roleOptions.forEach((roleOption) => roleOptionsToLabelMap.set(roleOption.id, roleOption.label))

const viewerRoleOption: RoleOption = {
  id: UserRole.Viewer,
  label: 'Viewer',
}

const adminStaffRoleOptions: RoleOption[] = [
  {
    id: UserRole.Admin,
    label: 'Admin',
  },
  {
    id: UserRole.Staff,
    label: 'Staff',
  },
]

const nonAdminRoleOptions: RoleOption[] = [
  viewerRoleOption,
  { id: UserRole.Vendor, label: 'Vendor' },
  { id: UserRole.EndUser, label: 'End user' },
]

const staffRoleOptions: RoleOption[] = [...adminStaffRoleOptions, viewerRoleOption]

const adminStaffRoles: UserRole[] = adminStaffRoleOptions.map((role) => role.id)
const staffRoles: UserRole[] = staffRoleOptions.map((role) => role.id)
const nonAdminRoles: UserRole[] = nonAdminRoleOptions.map((role) => role.id)

const adminStaffRolesSet: Set<UserRole> = new Set(adminStaffRoles)
const staffRolesSet: Set<UserRole> = new Set(staffRoles)

const serverRoleToUserRole: { [x: string]: keyof typeof UserRole } = {
  ADMIN: 'Admin',
  END_USER: 'EndUser',
  STAFF: 'Staff',
  VIEWER: 'Viewer',
  SYSTEM: 'System',
  VENDOR: 'Vendor',
}

export {
  roleOptions,
  roleOptionsToLabelMap,
  adminStaffRoleOptions,
  adminStaffRoles,
  adminStaffRolesSet,
  staffRoles,
  serverRoleToUserRole,
  staffRolesSet,
  nonAdminRoles,
}
