import React from 'react'
import GatedRoute from './GatedRoute'
import RouteWithSubRoutes from './RouteWithSubRoutes'
import useCurrentUser from '../../hooks/auth/useCurrentUser'
import PageLoading from '../../components/PageLoading'
import appRoutes, { RouteConfig } from '../../routes'
import { constructNestedPath } from '../../helpers/routeHelpers'
import { getIsStaffUser } from '../../helpers/dataHelpers'

export type GatedRouteWithSubRoutesProps = RouteConfig & {
  isGatePassed: boolean
  redirectPathname: string
}

function GatedRouteWithSubRoutes(route: GatedRouteWithSubRoutesProps): React.ReactElement {
  const { currentUser, loading, error } = useCurrentUser()

  const isStaffUser = React.useMemo(() => getIsStaffUser(currentUser), [currentUser])

  const apolloErrorObject = React.useMemo(() => {
    return {
      error,
    }
  }, [error])

  const routeForGateFail = React.useMemo(() => {
    // If an end user is logged in and they are accessing a staff route,
    // send them to the portal home page
    if (!route.isGatePassed && currentUser != null && !isStaffUser) {
      return appRoutes.portal.root
    }

    // Otherwise they're not authenticated, so send them to login
    return constructNestedPath([appRoutes.auth.root, route.redirectPathname])
  }, [route, currentUser, isStaffUser])

  if (loading) return <PageLoading />
  return (
    <GatedRoute
      isGatePassed={route.isGatePassed}
      routeForGateFail={routeForGateFail}
      apolloObject={{ apolloErrorObject }}
    >
      <RouteWithSubRoutes key={route.id} routes={[route]} />
    </GatedRoute>
  )
}

export default GatedRouteWithSubRoutes
