import React from 'react'
import { createRoot } from 'react-dom/client'

import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/react'
import './index.css'
import App from './App'
import { unregister } from './registerServiceWorker'

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://d600de818c7e427f9b8cdde5983484d5@o174923.ingest.sentry.io/5343478' })

  datadogRum.init({
    // defaults to staging
    applicationId: process.env.REACT_APP_DATADOG_APP_ID ?? 'a9474889-ae29-4738-9a39-9c914907ea09',
    clientToken:
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? 'pub22f240318e61cf3a0d073fa1ecc30727',
    site: 'datadoghq.com',
    service: process.env.NODE_ENV === 'production' ? 'nodafi' : 'nodafi-staging',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    // decrease this if capturing all user sessions is too expensive
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(<App />)

unregister()
