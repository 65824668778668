import { useCookies } from 'react-cookie'
import { cookieKeys } from '../../constants'
import { convertAnyValidFormatToDate } from '../../helpers/humanReadableTime'

const { AUTH_TOKEN } = cookieKeys

// custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
const useAuthToken = () => {
  //we use react-cookies to access our cookies
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TOKEN])

  const expiryDate = convertAnyValidFormatToDate(
    process.env.REACT_APP_AUTH_TOKEN_COOKIE_EXPIRES ?? '1h',
  )

  // this function allows to save any string in our cookies, under the key "authToken"
  const setAuthToken = (authToken) =>
    setCookie(AUTH_TOKEN, authToken, {
      path: '/',
      ...(expiryDate && {
        expires: expiryDate,
      }),
    })

  //this function removes the key "authToken" from our cookies. Useful to logout
  const removeAuthToken = () => removeCookie(AUTH_TOKEN, { path: '/' })

  return [cookies[AUTH_TOKEN], setAuthToken, removeAuthToken] as const
}

export default useAuthToken
