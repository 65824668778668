import { styled } from 'styled-components'

const StikcyMuiTh = styled.th(() => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.01071em',
  display: 'table-cell',
  verticalAlign: 'inherit',
  textAlign: 'left',
  padding: '16px',
  color: 'rgba(0, 0, 0, 0.87)',
  maxHeight: '48px',
  paddingTop: '4px',
  paddingBottom: '4px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: 'sticky',
  top: '0px',
  zIndex: 100,
  backgroundColor: '#fff',
  width: 'auto',
  minWidth: '80px',
}))

const TrunatedStickyMuiTH = styled(StikcyMuiTh)(() => ({
  width: '150px',
  maxWidth: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

// styles for OfflineInfoBanner

const OfflineToastContent = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  backgroundColor: '#d32f2f',
  borderRadius: '4px',
}))

const OfflineToastHeading = styled.h3(() => ({
  color: 'white',
  margin: '0 0 4px 0',
  fontSize: '16px',
  fontWeight: 'bold',
}))

const OfflineToastBody = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const OfflineToastMessage = styled.span(() => ({
  color: 'white',
  fontSize: '12px',
  lineHeight: '1.3',
  flex: 1,
  marginRight: '12px',
}))

const OfflineToastActionButton = styled.button(() => ({
  padding: '6px 12px',
  backgroundColor: 'rgba(255, 255, 255, 0.2)', // Soft danger button
  color: 'white',
  border: '1px solid white',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '13px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  flexShrink: 0,
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: '0 0 0 2px rgba(255, 255, 255, 0.5)',
  },
}))

export {
  StikcyMuiTh,
  TrunatedStickyMuiTH,
  OfflineToastContent,
  OfflineToastMessage,
  OfflineToastActionButton,
  OfflineToastBody,
  OfflineToastHeading,
}
