const FORGOT_PASSWORD_CONFIRMATION_TEXT =
  'Great, we shot you an email to make sure it is really you. Please check your inbox and click the confirmation link.'

const INVALID_TOKEN_TEXT = 'Invalid token, please request a new one.'
const INVALID_TEMP_AUTH_CODE = "That code wasn't valid. Give it another go!"
const GENERIC_ERROR_TEXT = 'Something went wrong. Please contact hello@nodafi.com.'
const NETWORK_UNAVAILABLE_ERROR_TEXT =
  'Try again. If this problem persists, please contact hello@nodafi.com.'
const NETWORK_ERROR = 'Network error. Please check your connection and try again.'

const END_USERS_LOGIN_INSTRUCTION = 'Looking to check on a request you submitted?'

const EDIT_TICKET = {
  SEND_VENDOR_INVITES_TEXT:
    'Select which members of this organization you would like to send this ticket to. The recipients will receive an email with a link to access this ticket and its details',
}
const REFRESH_TOKEN_EXPIRED = 'Your session expired. Please log in again.'
export {
  FORGOT_PASSWORD_CONFIRMATION_TEXT,
  INVALID_TOKEN_TEXT,
  GENERIC_ERROR_TEXT,
  NETWORK_ERROR,
  EDIT_TICKET,
  INVALID_TEMP_AUTH_CODE,
  REFRESH_TOKEN_EXPIRED,
  END_USERS_LOGIN_INSTRUCTION,
  NETWORK_UNAVAILABLE_ERROR_TEXT,
}
