import React from 'react'
import { CircularProgress } from '@mui/joy'
import PageContainer from './Layout/PageContainer'

function PageLoading(): JSX.Element {
  return (
    <PageContainer>
      <CircularProgress color="neutral" />
    </PageContainer>
  )
}

export default PageLoading
