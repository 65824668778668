// index.js
import * as React from 'react'
import { IntercomProvider as ReactUseIntercomProvider } from 'react-use-intercom'

type Props = {
  children: React.ReactNode
}

const IntercomProvider = React.memo<Props>(({ children }) => {
  const appId = process.env.REACT_APP_INTERCOM_APP_ID ?? 'l1wxef3m'

  return <ReactUseIntercomProvider appId={appId}>{children}</ReactUseIntercomProvider>
})

IntercomProvider.displayName = 'IntercomProvider'
export default IntercomProvider
