import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { routeWithMatchAny } from '../../helpers/routeHelpers'
import appRoutes, { RouteConfig } from '../../routes'

type RouteWithSubRoutesProps = {
  routes: RouteConfig[]
  redirectPathname?: string
}

function RouteWithSubRoutes({ routes, redirectPathname }: RouteWithSubRoutesProps) {
  return (
    <Routes>
      {routes.map((route) => {
        // ???
        const RouteComponent = route.component as any
        return (
          <Route
            key={route.id}
            path={route.permittedRoles != null ? routeWithMatchAny(appRoutes.root) : route.path}
            element={
              <>
                {route.component ? (
                  <RouteComponent routes={route.routes} />
                ) : (
                  <Navigate to={route.redirect?.to as string} replace />
                )}
                {route.routes?.length ? (
                  <RouteWithSubRoutes routes={route.routes} redirectPathname={redirectPathname} />
                ) : null}
              </>
            }
          ></Route>
        )
      })}
    </Routes>
  )
}

export default RouteWithSubRoutes
