import numbered from 'numbered'
import pluralize from 'pluralize'
import ms from 'ms'

// Adapted from https://github.com/agenda/human-interval
const units: any = {}
units.second = 1000
units.minute = units.second * 60
units.hour = units.minute * 60
units.day = units.hour * 24
units.week = units.day * 7
units.month = units.day * 30
units.year = units.day * 365

const regexp = /(second|minute|hour|day|week|month|year)s?/

export const humanReadableToMs = (time) => {
  if (!time || typeof time === 'number') {
    return time
  }

  let result = Number.NaN

  time = time.replace(/([^a-z\d.-]|and)+/g, ' ')

  for (;;) {
    const match = time.match(regexp)
    if (!match) {
      return result
    }

    const matchedNumber = time.slice(0, match.index).trim()
    const unit = units[match[1]]
    let number = 1
    if (matchedNumber.length > 0) {
      number = Number.parseFloat(matchedNumber)
      if (Number.isNaN(number)) {
        number = numbered.parse(matchedNumber)
      }
    }

    if (Number.isNaN(result)) {
      result = 0
    }

    result += number * unit
    time = time.slice(match.index + match[0].length)
  }
}

const getMsBreakdown = (ms) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000))
  const daysms = ms % (24 * 60 * 60 * 1000)
  const hours = Math.floor(daysms / (60 * 60 * 1000))
  const hoursms = ms % (60 * 60 * 1000)
  const minutes = Math.floor(hoursms / (60 * 1000))
  const minutesms = ms % (60 * 1000)
  const seconds = Math.floor(minutesms / 1000)
  return { days, hours, minutes, seconds }
}

export const getTimeBreakDown = (date) => {
  const year = date.getFullYear()
  const day = date.getDay()
  const month = date.getMonth()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  return { year, day, month, hours, minutes, seconds }
}

export const msToHumanReadable = (time) => {
  const timeBreakdown = getMsBreakdown(time)
  return Object.keys(timeBreakdown)
    .reduce((acc, key) => {
      const value = timeBreakdown[key]
      if (value > 0) {
        const parsedValue = pluralize(key, value, true)
        return acc.concat(`${parsedValue} `)
      }
      return acc
    }, '')
    .trim()
}

export const convertAnyValidFormatToDate = (input: string) => {
  const milliseconds: number | undefined = ms(input)
  if (milliseconds == undefined) return null
  return new Date(Date.now() + milliseconds)
}
