import * as React from 'react'
import Grid, { GridProps } from '@mui/joy/Grid'
import styled from 'styled-components'

interface PageContainerGridProps extends GridProps {
  width?: string | undefined
}
const PageContainerGrid = styled(Grid)<PageContainerGridProps>(({ theme, width }) => ({
  height: '100%',
  overflow: 'hidden',
  padding: theme.spacing(3),
  ...(width != undefined && {
    width,
  }),
}))
// types
type PageContainerProps = {
  children?: React.ReactNode
  className?: string
  width?: string | number
  [x: string]: any
}
const PageContainer: React.FC<PageContainerProps> = ({ children, className, width, ...rest }) => {
  const widthVal = width ? String(width) : undefined
  return (
    <PageContainerGrid container className={className} width={widthVal} {...rest}>
      {children}
    </PageContainerGrid>
  )
}

export default PageContainer
