import * as React from 'react'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import { parseSubdomain } from '../../helpers/stringUtils'
import useCurrentUser from '../../hooks/auth/useCurrentUser'

// Types
type Props = {
  children: React.ReactNode
}

const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
})

const subdomain = parseSubdomain(window.location.hostname)

/**
 * Setup from https://github.com/optimizely/react-sdk#set-user-asynchronously
 *
 * User object should look like:
 * user={{
      id: 'user123',
      attributes: {
        subdomain: 'demo',
        is_internal: false,
        some_other_one: true,
      },
    }}
 */
const FeatureProvider = React.memo<Props>(({ children }) => {
  const { currentUser } = useCurrentUser()
  const userPromise = React.useCallback(() => {
    try {
      return {
        id: currentUser ? currentUser.id : 'anonymous-request',
        attributes: {
          subdomain,
        },
      }
    } catch (error) {
      return {
        id: 'anonymous-request',
        attributes: {
          subdomain,
        },
      }
    }
  }, [currentUser])
  return (
    <OptimizelyProvider optimizely={optimizely} user={userPromise()} timeout={1000}>
      {children}
    </OptimizelyProvider>
  )
})

FeatureProvider.displayName = 'FeatureProvider'
export default FeatureProvider
