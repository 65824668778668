import React, { lazy } from 'react'
import { Route, useLocation, Routes, Navigate } from 'react-router-dom'
import { constructNestedPath, routeWithMatchAny } from '../common/helpers/routeHelpers'

// Constants
import appRoutes from '../common/routes'
import GatedRouteWithSubRoutes from '../common/providers/Router/GatedRouteWithSubRoutes'
import useCurrentOrganization from '../common/hooks/auth/useCurrentOrganization'
import useCurrentUser from '../common/hooks/auth/useCurrentUser'
import { staffRoles } from '../common/constants/roles'

// resolvers
const resolvers = {}

// Lazy
const CodeScanner = lazy(() => import('./pages/CodeScanner'))
const UserFeedback = lazy(() => import('./pages/UserFeedback'))
const FormPage = lazy(() => import('./pages/FormPage'))
const ViewTicket = lazy(() => import('./pages/ViewTicket'))
const LocationPageForm = lazy(() => import('./pages/FormPage/Landing/LocationPageFormLanding'))
const SpacePageForm = lazy(() => import('./pages/FormPage/Landing/SpacePageFormLanding'))
const TicketsTable = lazy(() => import('./pages/TicketsTable'))

const authRequiredRoutes = [appRoutes.portal.tickets.root, appRoutes.portal.tickets.viewTicket]

const portalRoutes = [
  {
    id: 'scanner',
    title: 'Code Scanner',
    permittedRoles: staffRoles,
    path: appRoutes.portal.codeScanner,
    component: CodeScanner,
  },
  {
    id: 'form-root',
    title: 'Form root',
    permittedRoles: staffRoles,
    path: appRoutes.portal.form.root,
    component: FormPage,
  },
  {
    id: 'feedback',
    title: 'Feedback',
    permittedRoles: staffRoles,
    path: appRoutes.portal.feedback,
    component: UserFeedback,
  },
  {
    id: 'tickets-root',
    title: 'Tickets',
    permittedRoles: staffRoles,
    path: appRoutes.portal.tickets.root,
    component: TicketsTable,
  },
  {
    id: 'view-ticket',
    title: 'View ticket',
    permittedRoles: staffRoles,
    path: appRoutes.portal.tickets.viewTicket,
    component: ViewTicket,
  },
  {
    id: 'spaces',
    title: 'Spaces',
    permittedRoles: staffRoles,
    path: appRoutes.portal.form.spaces,
    component: SpacePageForm,
  },
  {
    id: 'location-form',
    title: 'Location form',
    permittedRoles: staffRoles,
    path: appRoutes.portal.form.location,
    component: LocationPageForm,
  },
]

const Portal = React.memo(() => {
  const { pathname } = useLocation()
  const { isPortalAuthenticationRequired } = useCurrentOrganization()
  const { currentUser } = useCurrentUser()

  const isGatePassed = React.useCallback(
    (route) => {
      if (isPortalAuthenticationRequired && currentUser == null) {
        return false
      }
      // prevent unauthorized access to tickets list and viewTicket
      if (authRequiredRoutes.includes(route) && currentUser == null) {
        return false
      }
      return true
    },
    [isPortalAuthenticationRequired, currentUser],
  )

  return (
    <Routes>
      {portalRoutes.map((route) => {
        return (
          <Route
            key={route.id}
            path={routeWithMatchAny(route.path)}
            element={
              <GatedRouteWithSubRoutes
                key={route.id}
                {...route}
                redirectPathname={appRoutes.auth.login}
                isGatePassed={isGatePassed(route.path)}
              />
            }
          />
        )
      })}
      <Route
        path={`${appRoutes.portal.oldSpace}`}
        element={
          <Navigate
            to={constructNestedPath([appRoutes.portal.root, appRoutes.portal.form.root, pathname])}
            replace
          />
        }
      />

      <Route
        path={appRoutes.landing}
        element={<Navigate to={appRoutes.portal.form.root} replace />}
      />
    </Routes>
  )
})
Portal.displayName = 'Portal'
export { resolvers }
export default Portal
