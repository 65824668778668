import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ApolloError } from '@apollo/client'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

// types
type GatedRouteProps = {
  isGatePassed: boolean
  children: React.ReactNode
  routeForGateFail: string
  apolloObject: {
    apolloErrorObject: {
      error: ApolloError | undefined
    }
  }
}

function GatedRoute({
  children,
  isGatePassed,
  routeForGateFail,
  apolloObject: {
    apolloErrorObject: { error },
  },
}: GatedRouteProps) {
  const location = useLocation()

  return (
    <>
      {/* if there's a network error, gate will not pass but we don't want to redirect to login */}
      {error?.networkError || isGatePassed ? (
        children
      ) : (
        <Navigate to={routeForGateFail} replace state={{ from: location }} />
      )}
    </>
  )
}

export default GatedRoute
