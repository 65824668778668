import * as React from 'react'
import { loader as gqlLoader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import { Organization } from '../../../graphql/@types/schema'
const CurrentOrganizationQuery = gqlLoader('./data/CurrentOrganizationQuery.graphql')
import { parseSubdomain } from '../../helpers/stringUtils'

interface CurrentOrganizationQueryData {
  organizationBySubdomain: Organization
}

const useCurrentOrganization = () => {
  const subdomain = React.useMemo(() => parseSubdomain(window.location.hostname), [])

  const {
    data: organizationData,
    loading,
    error,
  } = useQuery<CurrentOrganizationQueryData>(CurrentOrganizationQuery, {
    variables: {
      subdomain,
    },
    skip: subdomain == null,
  })

  const currentOrganization = React.useMemo(
    () => organizationData?.organizationBySubdomain ?? null,
    [organizationData],
  )

  const isPortalAuthenticationRequired = React.useMemo(
    () =>
      currentOrganization?.settings.portalSettings.attributes.portalAuthenticationConfiguration
        .attributes.isAuthenticationRequired.value === true,
    [currentOrganization],
  )

  return { currentOrganization, loading, error, isPortalAuthenticationRequired }
}

export default useCurrentOrganization
