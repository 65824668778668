import React from 'react'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const Toast = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast--error {
    background-color: ${(props) => props.theme.palette.error.main};
  }
  .Toastify__toast--warning {
    background-color: ${(props) => props.theme.palette.warning.main};
  }
  .Toastify__toast--info {
    background-color: ${(props) => props.theme.palette.info.main};
  }
  .Toastify__toast--success {
    background-color: ${(props) => props.theme.palette.success.main};
  }
  .Toastify__toast-body {
    color: #ffffff;
  }
  .Toastify__close-button {
    color: #ffffff;
  }
`

const NotificationProvider = React.memo(() => (
  <Toast
    position="top-right"
    autoClose={5000}
    pauseOnFocusLoss={false}
    closeOnClick
    draggable
    pauseOnHover
    icon={false}
  />
))

NotificationProvider.displayName = 'NotificationProvider'
export default NotificationProvider
