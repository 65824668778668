import * as React from 'react'
import * as Sentry from '@sentry/react'
import { loader as gqlLoader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import {
  CurrentUserQueryQuery,
  CurrentUserQueryQueryVariables,
} from '../../../graphql/@types/schema'
const CurrentUserQuery = gqlLoader('./data/CurrentUserQuery.graphql')

type SetSentryUserContextParams = {
  id: string
  email: string
  subdomain: string
}

const setSentryUserContext = (userContext: SetSentryUserContextParams) => {
  Sentry.configureScope(function (scope) {
    scope.setUser(userContext)
  })
}

const useCurrentUser = () => {
  const { data, error, loading } = useQuery<CurrentUserQueryQuery, CurrentUserQueryQueryVariables>(
    CurrentUserQuery,
  )

  React.useEffect(() => {
    if (loading === false && data != null && data.currentUser != null) {
      const sentryInfo = {
        id: data.currentUser.id,
        email: data.currentUser.email as string,
        subdomain: data.currentUser.organization.subdomain,
      }
      setSentryUserContext(sentryInfo)
    }
  }, [data, error, loading])

  return { currentUser: data && data.currentUser, loading, error }
}

export type CurrentUser = CurrentUserQueryQuery['currentUser']
export type PartialCurrentUserWithRequiredRole = Partial<CurrentUser> & {
  role: CurrentUserQueryQuery['currentUser']['role']
}
export default useCurrentUser
