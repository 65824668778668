import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

// Components
import Loading from '../../components/Loading'
import { routeWithMatchAny } from '../../helpers/routeHelpers'

// Constants
import appRoutes from '../../routes'

// Pages
const PortalRoot = lazy(() => import('../../../portal'))
const StaffRoot = lazy(() => import('../../../staff'))
const AuthRoot = lazy(() => import('../../../auth'))
const SmartCodesRoot = lazy(() => import('../../../smartCodes'))
const NotFound = lazy(() => import('../../components/NotFound'))
const OnboardingPage = lazy(() => import('../../pages/Onboarding'))

const Router = React.memo(() => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={routeWithMatchAny(appRoutes.smartCodes.root)} element={<SmartCodesRoot />} />
          <Route path={routeWithMatchAny(appRoutes.staff.root)} element={<StaffRoot />} />
          <Route path={routeWithMatchAny(appRoutes.portal.root)} element={<PortalRoot />} />
          <Route path={routeWithMatchAny(appRoutes.auth.root)} element={<AuthRoot />} />
          <Route path={routeWithMatchAny(appRoutes.space)} element={<PortalRoot />} />
          <Route path={appRoutes.onboarding} element={<OnboardingPage />} />
          <Route path={appRoutes.root} element={<Navigate to={appRoutes.portal.root} replace />} />
          <Route path={appRoutes.notFound} element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
})
Router.displayName = 'Router'
export default Router
