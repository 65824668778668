import { useCookies } from 'react-cookie'
import { cookieKeys } from '../../constants'
import { convertAnyValidFormatToDate } from '../../helpers/humanReadableTime'

const { REFRESH_TOKEN } = cookieKeys

// custom hook to handle refreshToken - we use compositon to decouple the auth system and it's storage
const useRefreshToken = () => {
  //we use react-cookies to access our cookies
  const [cookies, setCookie, removeCookie] = useCookies([REFRESH_TOKEN])
  const expiryDate = convertAnyValidFormatToDate(
    process.env.REACT_APP_REFRESH_TOKEN_COOKIE_EXPIRES ?? '30d',
  )
  // this function allows to save any string in our cookies, under the key "refreshToken"
  const setRefreshToken = (refreshToken) =>
    setCookie(REFRESH_TOKEN, refreshToken, {
      path: '/',
      ...(expiryDate && {
        expires: expiryDate,
      }),
    })

  //this function removes the key "refreshToken" from our cookies. Useful to logout
  const removeRefreshToken = () => removeCookie(REFRESH_TOKEN, { path: '/' })

  return [cookies[REFRESH_TOKEN], setRefreshToken, removeRefreshToken]
}

export default useRefreshToken
