import { ApolloError, GraphQLErrors } from '@apollo/client/errors'
import flatMap from 'lodash/fp/flatMap'
import { GENERIC_ERROR_TEXT } from '../../strings'

export enum RequestErrorType {
  FORBIDDEN = 'FORBIDDEN',
  BAD_REQUEST = 'BAD_REQUEST',
  NETWORK_ERROR = 'NETWORK_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

// Taken from NestJS validation-error
export interface ValidationError {
  /**
   * Object that was validated.
   *
   * OPTIONAL - configurable via the ValidatorOptions.validationError.target option
   */
  target?: Record<string, any>
  /**
   * Object's property that hasn't passed validation.
   */
  property: string
  /**
   * Value that haven't pass a validation.
   *
   * OPTIONAL - configurable via the ValidatorOptions.validationError.value option
   */
  value?: any
  /**
   * Constraints that failed validation with error messages.
   */
  constraints?: {
    [type: string]: string
  }
  /**
   * Contains all nested validation errors of the property.
   */
  children?: ValidationError[]
  /**
   * A transient set of data passed through to the validation result for response mapping
   */
  contexts?: {
    [type: string]: any
  }
}

const extractConstraints = (errors: ValidationError[]): string[] => {
  const constraintErrors: string[] = []

  const recurse = (errors: ValidationError[]) => {
    for (const error of errors) {
      if (error.constraints) {
        constraintErrors.push(...Object.values(error.constraints))
      }
      if (error.children && error.children.length > 0) {
        recurse(error.children)
      }
    }
  }

  recurse(errors)
  return constraintErrors
}

const getMessages = (graphQLErrors: GraphQLErrors) =>
  flatMap(({ extensions, message }: any) => {
    if (extensions?.response && extensions.response.message) {
      return extensions.response.message as string
    }

    const invalidArgs: ValidationError[] = extensions?.invalidArgs
    if (invalidArgs) {
      const constraintErrors = extractConstraints(invalidArgs)
      return constraintErrors
    }
    const originalErrorMessages =
      (extensions?.originalError && extensions.originalError.message) || []

    if (originalErrorMessages.length > 0) return originalErrorMessages

    if (message) {
      return [message]
    }

    return []
  }, graphQLErrors)

const parseError = (error: ApolloError): { type: RequestErrorType; messages: string[] } => {
  const { graphQLErrors, message } = error
  if (graphQLErrors) {
    // New server format for BAD_REQUEST
    if (graphQLErrors[0] && graphQLErrors[0].extensions) {
      switch (graphQLErrors[0].extensions.code) {
        case 'BAD_USER_INPUT':
        case 'BAD_REQUEST': {
          const messages = getMessages(graphQLErrors)
          return {
            type: RequestErrorType.BAD_REQUEST,
            messages,
          }
        }
        case 'FORBIDDEN':
          return {
            type: RequestErrorType.FORBIDDEN,
            messages: getMessages(graphQLErrors),
          }
        case 'INTERNAL_SERVER_ERROR':
          return {
            type: RequestErrorType.INTERNAL_SERVER_ERROR,
            messages: [GENERIC_ERROR_TEXT],
          }
        default:
          return {
            type: RequestErrorType.BAD_REQUEST,
            messages: graphQLErrors.map(({ message }) => message),
          }
      }
    }
  }
  return {
    type: RequestErrorType.NETWORK_ERROR,
    messages: [message],
  }
}

export default parseError
