import { TableColumn, TimeDimensionGranularity } from '@cubejs-client/core'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import memoizeOne from 'memoize-one'
import dateFnsFormat from 'date-fns/format'
import isToday from 'date-fns/isToday'
import startOfDay from 'date-fns/startOfDay'
import sub from 'date-fns/sub'
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears'
import differenceInCalendarQuarters from 'date-fns/differenceInCalendarQuarters'
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths'
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import differenceInHours from 'date-fns/differenceInHours'
import compareAsc from 'date-fns/compareAsc'
import isArray from 'lodash/fp/isArray'
import mergeWith from 'lodash/fp/mergeWith'
import { dinero, toDecimal } from 'dinero.js'
import { USD } from '@dinero.js/currencies'
import { isThisYear } from 'date-fns'
import { FieldValues, Path, SetValueConfig, UseFormSetValue } from 'react-hook-form'
import { staffRoles, nonAdminRoles } from '../constants/roles'
import { PartialCurrentUserWithRequiredRole } from '../hooks/auth/useCurrentUser'
import { NullOrUndefined } from '../types'

// TODO we need to coerce the type of these table config render functions to a single type
// because we're forcing a cascade of an any everywhere across the application.
// TODO coerce type usage across the application so these generics don't have to be optional.
export type TableData<
  Row = any,
  Column extends { key: string; rowData: (row: Row) => any } = any,
> = {
  rows: { [key: string]: any }[]
  columns: Column[]
}

type AdditionalProps = {
  isDisabled?: boolean
  options?: {
    id: string
    label: string
  }[]
}

// Copied from nodafi_mobile. Could be good to extract this kinda stuff into a JS package
// https://github.com/jpandl19/nodafi_mobile/blob/d596ad82f067c4c66afb2e4f750f4708853fa768/src%2Fcommon%2Fhelpers%2FdataHelpers.js

type User = {
  role: 'ADMIN' | 'STAFF' | 'END_USER'
}

const getSectionsByCreatedAt = <T extends { createdAt: string | Date }>(
  records: T[],
): { title: string; data: T[] }[] => {
  const recordByDays = groupBy(records, (record) => startOfDay(new Date(record.createdAt)))
  return Object.keys(recordByDays).map((key) => ({
    data: recordByDays[key],
    title: isToday(new Date(key)) ? 'Today' : dateFnsFormat(new Date(key), 'MMM do'),
  }))
}

const filterStaff = (users: User[]) =>
  users.filter((user) => user.role === 'ADMIN' || user.role === 'STAFF')

const range = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)

const sortString = (item1: string, item2: string) => item1 && item1.localeCompare(item2)
const sortNumber = (item1: number, item2: number) => item1 - item2
const sortDate = (item1: Date, item2: Date) => compareAsc(item1, item2)

const formatDateTime = (dateTime: string | Date) => {
  const date = new Date(dateTime)
  return date.toLocaleString()
}

// eslint-disable-next-line quotes
const formatDate = (date: Date) => dateFnsFormat(date, "yyyy-MM-dd'T'HH:mm:ss")
const formatDateSimple = (date: Date) => dateFnsFormat(date, 'yyyy-MM-dd')
const formatTime = (date: Date): string => dateFnsFormat(date, 'p')
const formatDateBasic = (date: Date) => {
  const isSameYear = isThisYear(date)
  return isSameYear
    ? dateFnsFormat(date, 'MMM d') // Jul 13
    : dateFnsFormat(date, 'MMM d yyyy') // Jul 13 2022
}

// get prior date.
const getPriorDateByDayNumber = (days: number) => {
  return sub(new Date(), {
    days,
  })
}

/**
 * TODO: this function is called by every page in a different way that can't be coerced to a singular generic type.
 *
 * do this data mapping when building the table instead of using this type unsafe helper
 *
 * @deprecated
 */
const getFormattedTableData = memoizeOne(
  <Row, Column extends { key: string; rowData: (row: Row) => any }>(
    columns: Column[],
    data: Row[] = [],
  ): any => ({
    columns,
    rows: data.map((row) =>
      columns.reduce<{ [key: string]: string }>(
        (result, column) => ({ ...result, [column.key]: column.rowData(row) }),
        {},
      ),
    ),
  }),
)

// https://stackoverflow.com/questions/19700283/how-to-convert-time-milliseconds-to-hours-min-sec-format-in-javascript
const msToReadableTime = (millisec: number) => {
  const seconds = Number((millisec / 1000).toFixed(1))

  const minutes = Number((millisec / (1000 * 60)).toFixed(1))

  const hours = Number((millisec / (1000 * 60 * 60)).toFixed(1))

  // const days = Number((millisec / (1000 * 60 * 60 * 24)).toFixed(1))

  if (seconds < 60) {
    return { value: seconds, unit: 'seconds' }
  } else if (minutes < 60) {
    return { value: minutes, unit: 'minutes' }
  } else {
    return { value: hours, unit: 'hours' }
  }
  // Just sticking with hours now.
  // else {
  //   return { value: days, unit: 'days' }
  // }
}

/**
 * Formats a time duration in milliseconds to the format "#d #h #m",
 * where d is the number of days, h is the number of hours, and m is the number of minutes.
 * If any of the values are 0, they will not be displayed in the output.
 *
 * @param {number} milliseconds - The time duration in milliseconds.
 * @returns {string} The formatted time duration string in the format "#d #h #m".
 */
function formatNiceTime(milliseconds: number): string {
  const ONE_MINUTE = 60 * 1000 // in milliseconds
  const ONE_HOUR = 60 * ONE_MINUTE
  const ONE_DAY = 24 * ONE_HOUR

  const days = Math.floor(milliseconds / ONE_DAY)
  const hours = Math.floor((milliseconds % ONE_DAY) / ONE_HOUR)
  const minutes = Math.floor((milliseconds % ONE_HOUR) / ONE_MINUTE)

  let formattedTime = ''

  if (days > 0) {
    formattedTime += `${days}d `
  }
  if (hours > 0) {
    formattedTime += `${hours}h `
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m`
  }

  return formattedTime === '' ? '-' : formattedTime.trim()
}

/**
 * Takes an object and sources and merges them, skipping the merging of arrays.
 *
 * @param {*} object - The destination object
 * @param {*} sources - The source objects
 * @returns merged object
 */
const mergeState = (object, sources) => {
  return mergeWith((a, b) => (isArray(b) ? b : undefined), object, sources)
}

/**
 * Takes an amount (number) and returns the correct format for currency.
 */
const currencyFormatter = (amount: number) => toDecimal(dinero({ amount, currency: USD }))
/**
 * Takes an amount '$54.17' and returns the integer version '5417' for the backend.
 */
const currencyAmountToInt = (amount) => Math.floor(Number(amount) * 100)

/**
 * Replaces an element at a specific index in an array.
 *
 * @param array - The original array.
 * @param index - The index of the element to be replaced.
 * @param value - The new value to replace at the specified index.
 * @returns A new array with the element replaced.
 */
function replaceAt<T>(array: T[], index: number, value: T): T[] {
  const ret = array.slice(0)
  ret[index] = value
  return ret
}

/**
 * Removes an element at a specific index from an array.
 *
 * @param array - The original array.
 * @param index - The index of the element to be removed.
 * @returns A new array with the element removed.
 */
function deleteAt<T>(array: T[], index: number): T[] {
  // Use filter to create a new array with all elements that are not at the given index
  return array.filter((_, i) => i !== index)
}

/**
 * Represents a type that has an 'id' property.
 */
interface Identifiable {
  id: string | number
}

/**
 * Replaces an object in an array based on its 'id'.
 *
 * @param params - An object containing the array, the id of the item to replace, and the new value.
 * @returns A new array with the specified object replaced.
 */
function replaceValue<T extends Identifiable>(params: { array: T[]; id: T['id']; value: T }): T[] {
  const { array, id, value } = params
  const index = array.findIndex((entry) => entry.id === id)
  if (index === -1) {
    throw new Error(`Item with id ${id} not found`)
  }
  return replaceAt(array, index, value)
}

type FindFn<T> = (item: T) => boolean

/**
 * Moves the first object found using the provided find function to the end of the array.
 *
 * @template T - The type of the elements in the array.
 * @param {T[]} arr - The input array to be modified.
 * @param {FindFn<T>} findFn - The callback function to find the desired object. Takes an element of type T and returns a boolean.
 * @returns {T[]} - The modified array with the object moved to the end.
 */
function moveObjectToEnd<T>(originalArray: T[], findFn: FindFn<T>): T[] {
  const arr = [...originalArray]
  // Find the index of the object in the array
  const index = arr.findIndex(findFn)

  // If the object is found, move it to the end
  if (index !== -1) {
    // Remove the object from its current position
    const [object] = arr.splice(index, 1)

    // Add the object to the end of the array
    arr.push(object)
  }

  return arr
}

/**
 * Type definition for the update function callback.
 */
type UpdateFn<T> = (item: T) => T

/**
 * Finds and updates an object in an array and then returns the new array.
 *
 * @template T - The type of the elements in the array.
 * @param {T[]} originalArray - The input array to be modified.
 * @param {(item: T) => boolean} findFn - The callback function to find the desired object. Takes an element of type T and returns a boolean.
 * @param {UpdateFn<T>} updateFn - The callback function to update the found object. Takes an element of type T and returns an updated element of the same type.
 * @returns {T[]} - The modified array with the updated object.
 */
function updateObjectInArray<T>(
  originalArray: T[],
  findFn: (item: T) => boolean,
  updateFn: UpdateFn<T>,
): T[] {
  const arr = [...originalArray]

  // Find the index of the object in the array
  const index = arr.findIndex(findFn)

  // If the object is found, update it
  if (index !== -1) {
    // Update the object using the provided update function
    const updatedObject = updateFn(arr[index])

    // Replace the object in the array with the updated object
    arr[index] = updatedObject
  }

  return arr
}

/**
 * Finds and updates an object in an array, or inserts it if not found, then returns the new array.
 *
 * @template T - The type of the elements in the array.
 * @param {T[]} originalArray - The input array to be modified.
 * @param {(item: T) => boolean} findFn - The callback function to find the desired object. Takes an element of type T and returns a boolean.
 * @param {UpdateFn<T>} updateFn - The callback function to update the found object. Takes an element of type T and returns an updated element of the same type.
 * @param {T} newItem - The new item to be inserted if the find function does not locate an existing item.
 * @returns {T[]} - The modified array with the updated or inserted object.
 */
function upsertObjectInArray<T>(
  originalArray: T[],
  findFn: (item: T) => boolean,
  updateFn: UpdateFn<T>,
  newItem: T,
): T[] {
  const index = originalArray.findIndex(findFn)

  // If the object is found, update it
  if (index !== -1) {
    const updatedObject = updateFn(originalArray[index])

    // Replace the object in the array with the updated object
    originalArray[index] = updatedObject
    return originalArray
  }

  // If the object is not found, insert the new item
  return [...originalArray, newItem]
}

/**
 * Takes an object and the number of defined values.
 *
 * @param obj The object to check
 * @returns The number of defined values
 */
const getNumberOfDefinedValues = (obj) =>
  Object.values(obj).filter((val) => val !== undefined).length

const sortByPosition = (objA, objB) => {
  return objA.position - objB.position
}

const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getCurrentYear = () => new Date().getFullYear()

const getTotalDaysInMonth = (year: number, month = 1): number => new Date(year, month, 0).getDate()

// rrule generates dates that appear to be UTC but are actually TZ-based
// we want to create new dates that are in true UTC, so we are removing the TZ offset
// tz offset is positive when local is behind UTC (western hemisphere) and negative otherwise
// so adding the offset undoes the effect of the TZ
const convertRRuleDateToUTC = (date: Date): Date =>
  new Date(date.getTime() + new Date().getTimezoneOffset() * 60000)

function determineTimeDimension(
  startDate: Date,
  endDate: Date,
): TimeDimensionGranularity | undefined {
  if (differenceInCalendarYears(endDate, startDate) > 1) {
    return 'year'
  }
  if (differenceInCalendarQuarters(endDate, startDate) > 1) {
    return 'quarter'
  }
  if (differenceInCalendarMonths(endDate, startDate) > 1) {
    return 'month'
  }
  if (differenceInCalendarWeeks(endDate, startDate) > 1) {
    return 'week'
  }
  if (differenceInCalendarDays(endDate, startDate) > 1) {
    return 'day'
  }
  if (differenceInHours(endDate, startDate) > 1) {
    return 'hour'
  }
}

function flattenCubeTableColumns(columns): TableColumn[] {
  return (columns || []).reduce((memo, column) => {
    if (column.children) {
      return [...memo, ...flattenCubeTableColumns(column.children)]
    }

    return [...memo, column]
  }, [])
}

function formatCubeValue(
  value: boolean | number | string,
  dataInfo: {
    type: string | number
    format?: string
    meta?: { subFormat?: string; units?: string }
  },
): string {
  if (value == undefined) {
    return 'Ø'
  }

  const { type, format, meta } = dataInfo

  if (type === 'boolean') {
    if (typeof value === 'boolean') {
      return value.toString()
    } else if (typeof value === 'number') {
      return Boolean(value).toString()
    }

    return value
  }

  if (typeof value === 'string' && type === 'time') {
    return String(dateFnsFormat(new Date(value), 'MMM d yyyy'))
  }

  if (typeof value === 'number' && type === 'number' && format === 'percent') {
    return `${value.toFixed(2)}%`
  }

  if (typeof value === 'number' && isFinite(value) && type === 'number' && format === 'currency') {
    return `$${currencyFormatter(value)}`
  }

  if (typeof value === 'number' && type === 'number' && meta?.subFormat === 'duration') {
    // If units is seconds, multiply by 1000. Else it's ms so leave alone
    const multiplier = meta?.units === 'seconds' ? 1000 : 1
    return formatNiceTime(value * multiplier)
  }

  return value.toString()
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

const setMultipleValues = <T extends FieldValues>(
  values: Partial<T>,
  setValue: UseFormSetValue<T>,
  options?: SetValueConfig,
) => {
  for (const [key, value] of Object.entries(values)) {
    setValue(key as Path<T>, value, {
      shouldDirty: options?.shouldDirty !== undefined ? options.shouldDirty : true,
      shouldTouch: options?.shouldTouch !== undefined ? options.shouldTouch : true,
      shouldValidate: options?.shouldValidate !== undefined ? options.shouldValidate : true,
    })
  }
}

const addAdditionalPropsToFormData = <T>(
  formFields: T[],
  additionalProps: AdditionalProps,
  condition: (item: T) => boolean,
) =>
  formFields.map((item) => {
    if (condition(item)) {
      const newItem = {
        ...item,
        ...additionalProps,
      }
      return newItem
    }
    return item
  })

const getIsStaffUser = (currentUser?: PartialCurrentUserWithRequiredRole) =>
  currentUser != null && staffRoles.includes(currentUser.role)

const getIsNonAdminUser = (currentUser: PartialCurrentUserWithRequiredRole | NullOrUndefined) =>
  currentUser != null && nonAdminRoles.includes(currentUser.role)

const deepEqual = <T>(val1: T, val2: T): boolean => {
  return isEqual(val1, val2)
}

/**
 * Appends an item to an array if it doesn't exist based on a provided finding mechanism.
 *
 * @param {T[]} arr - The array to check and possibly append to.
 * @param {T} item - The item to possibly append to the array.
 * @param {function(T, T): boolean} finder - A function that checks if the item is in the array.
 *                                           It should return true if the item is found, otherwise false.
 * @returns {T[]} - Returns the (possibly modified) array.
 */
function appendIfNotFound<T>(arr: T[], item: T, finder: (a: T, b: T) => boolean): T[] {
  // Check if the item exists in the array using the finder function
  const exists = arr.some((existingItem) => finder(existingItem, item))

  // If the item doesn't exist, push it to the array
  if (exists === false) {
    arr.push(item)
  }

  return arr
}

function getArrayFirstItemNameAndRemainingItemCount<T extends { name: string }>(arr: T[]) {
  if (arr.length === 0) {
    return 'None'
  } else if (arr.length === 1) {
    return arr[0].name
  }
  const [firstItem, ...remainingItems] = arr
  return `${firstItem.name} + ${remainingItems.length} more`
}

function removeKeysNotInHeaders<
  T extends Record<string, unknown>,
  P extends Record<string, unknown>,
>(data: T[], headers: P): T[] {
  return data.map((obj) => {
    const newObj: T = {} as T
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(headers, key)) {
        newObj[key] = obj[key]
      }
    }
    return newObj
  })
}

/**
 *
 * @param obj
 * @returns bool
 */

function isObject(obj: any): boolean {
  return typeof obj === 'object' && obj !== null
}

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result
      resolve(dataUrl)
    }
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(blob)
  })
}

const truncateAndRemoveSpaces = (str: string, maxLength: number) => {
  // Remove spaces and truncate the string
  return str.replace(/\s+/g, '').slice(0, maxLength)
}

export {
  flattenCubeTableColumns,
  formatCubeValue,
  determineTimeDimension,
  getSectionsByCreatedAt,
  filterStaff,
  getIsStaffUser,
  range,
  sortString,
  sortNumber,
  sortDate,
  formatDateTime,
  formatNiceTime,
  formatTime,
  formatDateSimple,
  formatDate,
  formatDateBasic,
  msToReadableTime,
  getFormattedTableData,
  mergeState,
  currencyFormatter,
  currencyAmountToInt,
  replaceAt,
  deleteAt,
  replaceValue,
  getNumberOfDefinedValues,
  sortByPosition,
  reorder,
  getPriorDateByDayNumber,
  getTotalDaysInMonth,
  getCurrentYear,
  convertRRuleDateToUTC,
  validateEmail,
  moveObjectToEnd,
  upsertObjectInArray,
  updateObjectInArray,
  setMultipleValues,
  deepEqual,
  appendIfNotFound,
  addAdditionalPropsToFormData,
  getArrayFirstItemNameAndRemainingItemCount,
  removeKeysNotInHeaders,
  isObject,
  getIsNonAdminUser,
  blobToBase64,
  truncateAndRemoveSpaces,
}
