import { isLastCharacterSlash, removeExtraSlashes } from './stringUtils'

const routeWithMatchAny = (route: string): string =>
  isLastCharacterSlash(route) ? `${route}*` : `${route}/*`

const _removeForwardSlashesFromRoute = (str: string): string => {
  const path = removeExtraSlashes(str)
  return path
}

const constructNestedPath = (paths: string[]): string => {
  return _removeForwardSlashesFromRoute(paths.join('/'))
}
export { routeWithMatchAny, constructNestedPath }
