import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@mui/joy'

// Local imports

// Types

// Constants

// Styled components
const Container = styled.div(() => ({
  height: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}))

const LoadingContent = styled.div(() => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'sans-serif',
}))

// Helper functions

// Main Component
const Loading: React.FC = () => (
  <Container>
    <LoadingContent>
      <CircularProgress />
    </LoadingContent>
  </Container>
)

export default Loading
